<template>
    <div class="calendar_settings">
        <div class="header" v-if="isNewCalendar && calendarSettingsTitle">
            Calendar is created!
        </div>
        <div class="header" v-if="!isNewCalendar && calendarSettingsTitle">
            <div class="button_go_back" @click="goOut">
                <span class="iconify" data-icon="material-symbols:arrow-right-alt-rounded" data-rotate="180deg"
                    data-width="26" data-height="26"></span>
            </div>
            <div>{{ calendarSettingsTitle }}</div>
        </div>
        
        <transition :name="transitionName" mode="out-in">
            <router-view></router-view>
        </transition>
</div>
</template>

<script>
import CMS from '../service/cms/service';
import router from '../router';
export default {
    name: 'CalendarSettings',
    data() {
        return {
            transitionName: "",
        };
    },

    watch: {
        '$route.name': {
            handler: function (toRouteName, fromRouteName) {
                let isMenuPage = toRouteName == 'calendar-settings-menu'
                let isTemplateSettingsPage = fromRouteName == 'calendar-settings-template'
                
                this.transitionName = isMenuPage || isTemplateSettingsPage ?  'slide-right' : 'slide-left'
                console.log('current transition name :>> ', this.transitionName);
            },
            deep: true,
            immediate: true
        },
    },

    computed: {
        isNewCalendar() {
            return this.$route.params.isNewCalendar
        },
        calendarSettingsTitle() {
            let settingsName = ""
            switch (this.$route.name) {
                case "calendar-settings-menu":
                    settingsName = this.$route.params.calendarUid
                    if (this.$store.getters.getDefaultCalendar == settingsName)
                        settingsName = 'My calendar'
                    break;
                case "calendar-settings-main":
                    settingsName = "Calendar settings"
                    break;
                case "calendar-settings-template-menu":
                    settingsName = "Templates settings"
                    break;
                case "calendar-settings-host-menu":
                    settingsName = "Hosts settings"
                    break;
                case "calendar-settings-price-level":
                    settingsName = "Event Price Levels"
                    break;
                case "calendar-settings-rating-menu":
                    let ratingTypeName = this.$store.getters.calendarRatingTypeName
                    settingsName = `Event ${ratingTypeName} Rating`
                    break;
            }
            if (settingsName == "") return undefined

            return settingsName
        }
    },

    // created() {
    //     let calendar = this.$store.getters.calendarMainSettings
    //     let calendarIsUndefined = calendar.uid == ""
    //     if (calendarIsUndefined) {
    //         let uid = this.$route.params.calendarUid
    //         CMS.calendar.getByUid(uid).then(calendarData => {
    //             if (calendarData.err) {
    //                 throw new Error(calendarData.err)
    //             }
    //             this.$store.dispatch('set-last-calendar', calendarData)
    //         })
    //     }
    // },
    methods: {
        goOut() {
            let currentRoute = this.$route.name
            if (currentRoute == 'calendar-settings-menu') {
                this.goToCalendar()
            } else {
                this.goToSettingsMenu()
            }
        },
        goToSettingsMenu() {
            router.push({ name: "calendar-settings-menu" })
        },
        goToCalendar() {
            let uid = this.$route.params.calendarUid

            let query = this.$store.getters.calendarProperties
            if (query.selectedDate) {
                router.push({
                    name: "calendar",
                    params: { calendarUid: uid },
                    query: {
                        dt: query.selectedDate,
                        w: query.columnCount,
                    }
                })
                return
            }

            router.push({ name: "calendar", params: { calendarUid: uid } })
        },
    },
};


</script>

<style scoped>
.calendar_settings { 
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.header {
    height: 32px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 5px;

    background: lightgrey;
    position: fixed;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.button_go_back {
    display: flex;
    justify-content: center;
    align-items: center;
}



.fade-enter-active {
    animation: fade 0.4s;
}

.fade-leave-active {
    animation: fade 0.4s reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.slide-right-enter-active {
    animation: slide-right-in 0.5s forwards;
    -webkit-animation: slide-right-in 0.5s forwards;
}

.slide-right-leave-active {
    animation: slide-right-out 0.5s forwards;
    -webkit-animation: slide-right-out 0.5s forwards ;
}

.slide-left-enter-active {
    animation: slide-left-in 0.5s forwards;
    -webkit-animation: slide-left-in 0.5s forwards;
}

.slide-left-leave-active {
    animation: slide-left-out 0.5s forwards;
    -webkit-animation: slide-left-out 0.5s forwards ;
}

    
@keyframes slide-right-in {
    100% { transform: translateX(0%); opacity: 1; }
}

@-webkit-keyframes slide-right-in {
    100% { -webkit-transform: translateX(0%); opacity: 1;}
}
    
@keyframes slide-right-out {
    0% { transform: translateX(0%); opacity: 1;}
    100% { transform: translateX(100%); opacity: 0;}
}

@-webkit-keyframes slide-right-out {
    0% { -webkit-transform: translateX(0%); opacity: 1;}
    100% { -webkit-transform: translateX(100%); opacity: 0;}
}

@keyframes slide-left-in {
    100% { transform: translateX(0%); opacity: 1;}
}

@-webkit-keyframes slide-left-in {
    100% { -webkit-transform: translateX(0%); opacity: 1;}
}
    
@keyframes slide-left-out {
    0% { transform: translateX(0%); opacity: 1;}
    100% { transform: translateX(-100%); opacity: 0;}
}

@-webkit-keyframes slide-left-out {
    0% { -webkit-transform: translateX(0%); opacity: 1;}
    100% { -webkit-transform: translateX(-100%); opacity: 0;}
}

</style>